jQuery(function ($) {
	const Scripts = {
		varInit() {
			this.header = $("header");
			this.document = $(document);
		},

		valid() {
			var invalidClassName = 'invalid'
			var inputs = document.querySelectorAll('.subscription__input')
			inputs.forEach(function (input) {
				input.addEventListener('invalid', function () {
					input.classList.add(invalidClassName)
				})
				input.addEventListener('input', function () {
					if (input.validity.valid) {
						input.classList.remove(invalidClassName)
					}
				})
			})
		},

		init() {
			Scripts.varInit();
			Scripts.valid();
		},
	};

	$(document).ready(function () {
		Scripts.init();
	});
});